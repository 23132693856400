/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
@import "swiper/css/bundle";

ion-menu-button {
  color: var(--ion-color-primary);
}

ion-item {
  ion-icon[slot="start"] {
    margin-inline-end: 24px;
  }
}
ion-card {
  border-radius: 8px;
}

@media (min-width: 800px) {
  ion-modal.profile {
    --max-width: 400px;
    --height: 90%;
    --backdrop-opacity: var(--ion-backdrop-opacity, 0.8);
    --border-radius: 16px;
  }

  ion-modal.filters {
    --max-width: 470px;
    --backdrop-opacity: var(--ion-backdrop-opacity, 0.8);
  }
  ion-modal.age-consent {
    --width: 100%;
    --height: 100%;
    --backdrop-opacity: var(--ion-backdrop-opacity, 0.95);
    --background: transparent;
  }

  ion-modal.account {
    --border-radius: 16px;
    --backdrop-opacity: var(--ion-backdrop-opacity, 0.9);
  }

  ion-modal.guide {
    --width: 50%;
    --height: 80%;
    --backdrop-opacity: var(--ion-backdrop-opacity, 0.9);
    --border-radius: 16px;
  }
}
